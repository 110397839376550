<template>
    <div class="pt-1">
        <b-row v-show="boostInputDiv == true">
            <b-col md="9">
                <p>Boost your conversions by asking a leading question first</p>
            </b-col>
            <b-col md="3">
                <b-form-checkbox v-model="boostInput" :checked="boostInput" name="check-button" switch inline></b-form-checkbox>
            </b-col>
        </b-row>

        <b-row v-if="prop_siteSettingArray.setting.styleType == 2 && prop_siteSettingArray.setting.subGoal != 5 && prop_siteSettingArray.setting.subGoal != 7">
            <b-col md="9" >
                <p>Button Redirect Link (Button 2)</p>
            </b-col>
            <b-col md="3">
                <b-form-group>
                    <b-form-checkbox v-model="isModalButtonTwo" @change="handleToggleButton2" name="isModalButtonTwo" switch inline></b-form-checkbox>
                </b-form-group>
            </b-col>
        </b-row>

        <!-- TABS 1 -->
        <div v-if="boostInput == false">
            <b-tabs fill>
                <!-- COLORS -->
                <b-tab active title="COLORS">
                    <colors-setting :prop_siteSettingArray="siteSettingArray.setting" :isModalButtonTwoShowActive="isModalButtonTwoShowActive" />
                </b-tab>

                <!-- IMAGE -->
                <b-tab title="IMAGE" v-if="displayUploadImageTagDiv">
                    <image-setting :prop_siteSettingArray="siteSettingArray.setting" />
                </b-tab>

                <!-- TEXT -->
                <b-tab title="TEXT">
                    <text-setting :prop_siteSettingArray="siteSettingArray.setting" :isModalButtonTwoShowActive="isModalButtonTwoShowActive" />
                </b-tab>

                <!-- HTML -->
                <b-tab title="HTML" v-if="displayUploadHTMLTagDiv">
                    <html-setting :prop_siteSettingArray="siteSettingArray.setting" />
                </b-tab>

                <!-- CONTENT -->
                <b-tab title="CONTENT" v-if="siteSettingArray.setting.styleType == '5'">
                    <content-setting :prop_siteSettingArray="siteSettingArray.setting" />
                </b-tab>
            </b-tabs>
        </div>

        <!-- TABS 2 -->
        <div v-if="boostInput == true" v-show="boostInputDiv == true">
             <b-tabs fill>
                <!-- QUESTION -->
                <b-tab active title="Question">
                    <question-setting :prop_siteSettingArray="siteSettingArray.setting" />
                </b-tab>
                <!-- ANSWER 1 -->
                <b-tab  title="Answer 1">
                    <answer-one-setting :prop_siteSettingArray="siteSettingArray.setting" />
                </b-tab>
                <!-- ANSWER 2 -->
                <b-tab title="Answer 2">
                    <answer-two-setting :prop_siteSettingArray="siteSettingArray.setting" />
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
import { BCol, BRow, BTabs, BTab, BCardText, BFormCheckbox } from 'bootstrap-vue'

export default {
    components: {
        BCol, BRow, BTabs, BTab, BCardText, BFormCheckbox,
        ColorsSetting: () => import('./text-and-content-components/ColorsSetting'),
        ImageSetting: () => import('./text-and-content-components/ImageSetting'),
        TextSetting: () => import('./text-and-content-components/TextSetting'),
        QuestionSetting: () => import('./text-and-content-components/QuestionSetting'),
        AnswerOneSetting: () => import('./text-and-content-components/AnswerOneSetting'),
        AnswerTwoSetting: () => import('./text-and-content-components/AnswerTwoSetting'),
        HtmlSetting: () => import('./text-and-content-components/HtmlSetting'),
        ContentSetting: () => import('./text-and-content-components/ContentSetting')
    },
    props: {
        prop_siteSettingArray: {
            type: Object,
            default: () => { },
        }
    },
    data() {
        return {
            isModalButtonTwoShowActive: false,
            displayUploadImageTagDiv: true,
            displayUploadHTMLTagDiv: true,
            boostInputDiv: true
        }
    },
    computed: {
        /* GETS THE PROP VALUE INTO ANOTHER FUNCTION USING COMPUTED */
        siteSettingArray() {
            return this.prop_siteSettingArray;
        },

        /* GETS THE boostInput VALUE USING THE PROPS. */
        boostInput: {
            get() {
                if (_.get(this.prop_siteSettingArray, 'setting')) {
                    return this.prop_siteSettingArray.setting.isQuestion == 1 ? true : false;
                }
            },
            set(newValue) {
                this.prop_siteSettingArray.setting.isQuestion = (newValue == true) ? 1 : 0;
            }
        },

        isModalButtonTwo: {
            get() {
                if (_.get(this.prop_siteSettingArray, 'setting')) {
                    return this.prop_siteSettingArray.setting.isModalButtonTwo == 1 ? true : false;
                }
            },
            set(newValue) {
                this.prop_siteSettingArray.setting.isModalButtonTwo = (newValue == true) ? 1 : 0;
            }
        },

    },
    methods: {
        handleToggleButton2() {
            this.isModalButtonTwoShowActive = this.prop_siteSettingArray.setting.isModalButtonTwo === 1;
        }
    },
    watch: {
        prop_siteSettingArray: {
            handler(settingNewVal) {
                /* IF SUBGOAL IS 2: MODAL, 4: Page Takeover, 6: Side Modal NOT SELECTED THEN ONLY SHOW IMAGE UPLOAD DIV DISPLAY */
                if (_.includes(['1', '3', '5', '7'], settingNewVal.setting.styleType)) {
                    this.displayUploadImageTagDiv = false;
                } else {
                    this.displayUploadImageTagDiv = true;
                }
                /* IF SUBGOAL IS 2: MODAL, 4: Page Takeover, 6: Side Modal NOT SELECTED THEN ONLY SHOW IMAGE UPLOAD DIV DISPLAY */
                if (_.includes(['1', '3', '5'], settingNewVal.setting.styleType)) {
                    this.displayUploadHTMLTagDiv = false;
                } else {
                    this.displayUploadHTMLTagDiv = true;
                }
                /* IF SUBGOAL IS 2: MODAL, 4: Page Takeover, 6: Side Modal NOT SELECTED THEN ONLY SHOW IMAGE UPLOAD DIV DISPLAY */
                if (settingNewVal.setting.styleType == '7') {
                    this.boostInputDiv = false;
                } else {
                    this.boostInputDiv = true;
                }
            },
            deep: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.nav-tabs {
    padding-top: 1rem !important;
}
</style>